<template>
  <div class="about">
   <page-banner>
     <template #banner-txt>
       關於我們
     </template>
     <template #banner-img>
      <img v-lazy="banner" class="img-center">
     </template>
   </page-banner>
     <!--    -->
    <div class="common-bg padding-50">
         <div class="container">
          <div class="about-intro">
            <div class="about-intro-text">
              <h1 class="common-text">歷史與宗旨</h1>
              <div style="height: 20px"></div>
              <div v-html="historical.info">

              </div>
            </div>
            <div class="about-intro-img">
              <img :src="historical.image">
            </div>
          </div>
         </div>
    </div>

 <!--    -->

      <div class="common-bg padding-50 about-Frames-bg">
        <div class="container">
          <div class="about-Frames">
            <h1 class="common-text">架構圖</h1>
            <div class="padding-50">
              <img :src="architecture" class="img-center">
            </div>

          </div>
        </div>
      </div>

<!--    -->
    <div class="common-bg padding-50">
      <div class="container">
        <h1 class="common-text">名譽顧問成員</h1>
        <div class="member">
            <div class="member-list">
              <ul>
                <li v-for="(item, index) in info" :key="index" :class="{'active':activeIndex === index}">
                  <div class="member-item">
                    <div style="display: flex">
                      <div class="member-item-icon">
                        <i class="layui-icon layui-icon-username"></i>
                      </div>
                      <div class="member-item-title">
                        <h3>{{item.name}}</h3>
                        <p>{{item.occu}}</p>
                      </div>
                    </div>

                  </div>
                  <div class="member-intro">
                    <p v-for="info in item.info" :key="info">
                      {{info.name}}
                    </p>
                  </div>
                 </li>

              </ul>
            </div>
        </div>
        </div>
     </div>
<!--    -->

    <div class="common-bg padding-50 adviser-bg">
      <div class="container">
        <div class="adviser">
          <h1 class="common-text">理事會成員</h1>
        </div>
        <div class="adviser-list">
          <ul>
            <li v-for="(item, index) in member" :key="index">
              <div class="adviser-item">
                 <h3>{{item.name}}</h3>
                 <h5>{{item.occu}}</h5>
                <div class="adviser-item-txt">
                  <p v-for="info in item.info" :key="info">
                    {{info.name}}
                  </p>
                </div>

              </div>
            </li>

          </ul>
        </div>


      </div>
    </div>

  </div>
</template>
<script>
import pageBanner from "@/components/PageBanner";

import {getAboutInfo} from "@/network/home";

export default {
  name: "About",
  components: {
    pageBanner
  },
  data() {
    return{

      banner:{},
      historical: {},
      architecture: {},
      info:[],
      activeIndex: -1,
      member:[],
      isSubShow: false,
   }
  },
  created() {
    this.getAboutInfo()
  },

  methods: {
  getAboutInfo() {
      getAboutInfo().then(res =>{
      this.banner = res.data.data.banner;
      //
      this.historical = res.data.data.historical;
      //
      this.architecture = res.data.data.framework;
      this.info = res.data.data.consultant_member;
      this.member = res.data.data.council_member;
     })
    },


  },


}
</script>

<style scoped>
.about-intro{
  display: flex;
  align-items: center;
}
.about-intro-text{
  width: 48%;
}

.about-intro-img{
  width: 50%;
  padding-left: 50px;
}


.about-intro-text p{
  padding-bottom: 10px;
}

.about-Frames-bg{
  background: #fff url("~@/assets/img/about_bg1.png") top center no-repeat;
}
.member-list ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.member-list ul li{
  flex: 0 0 32%;
  max-width: 100%;
  margin-top: 40px;
  border: 1px solid #F76F01;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-sizing: border-box;
}
.member-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.member-item-icon{
  width: 48px;
  height: 48px;
  background: #F76F01;
  border-radius: 100%;
  margin-right: 20px;
}
.member-item-icon i{
  display: block;
  text-align: center;
  font-size: 24px;
  line-height: 48px;
  color: #fff;
}

.member-item-title h3{
  font-weight: bolder;
}
.member-item-title p{
  color: #332D30;
}

.member-intro{
  padding-left: 68px;

}

.active .member-item{
   border-bottom: 0;
   border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
 }

.active .member-intro{
  display: block;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-left: 1px solid #F76F01;
  border-right: 1px solid #F76F01;
  border-bottom: 1px solid #F76F01;
  padding-bottom: 40px;
}

.adviser-bg{
  background: #fff url("~@/assets/img/about_bg2.png") top center no-repeat;
}
.adviser-list ul{ display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.adviser-list ul li{
  flex: 0 0 30%;
  max-width: 100%;
  padding-top: 30px;
}


.adviser-list ul li

.adviser-item h3{
   font-weight: bolder;
}

.adviser-item h5{
  padding-top: 10px;
  color: #000;
  font-size: 17px;
}



@media only screen and (min-width:0px) and (max-width:991px) {
  .member-list ul li{
    flex: 0 0 48%;
    margin-top: 20px;
  }
  .adviser-list ul li{
    flex: 0 0 50%;
    padding-top: 20px;
  }

}

@media only screen and (min-width:0px) and (max-width:767px) {
  .member-list ul li{
    flex: 0 0 100%;
  }

  .about-intro{
    flex-wrap: wrap;
  }

  .about-intro-text{
    width: 100%;
  }
  .about-intro-img{
    width: 100%;
    padding-left: 0;
    margin-top: 20px;
  }

  .member-item{
    padding: 15px 15px 0 15px;
  }

  .member-intro{
    padding-left: 84px;

  }

  .active .member-intro{
     padding-bottom: 20px;
  }

  .adviser-more{
    padding: 5px 0;
    width: 70px;
    text-align: center;
    font-size: 10px;
   }

  .adviser-list ul li{
    padding-right: 10px;
  }

}






</style>